<template>
  <ValidationObserver rules="required" v-slot="{handleSubmit}">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onSubmit)"
      @close="isOpen = false"
      :loading="isLoading"
      submit="Submit"
      cancel="Close"
      button-class="bg-dynamicBackBtn text-white"
      :close-button="!timelines.length"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="isOpen = false" variant="secondary" />
          <p class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            View Timelines
          </p>
        </div>
      </template>
      <template v-if="!timelines.some((i) => i.participants)">
        <div class="flex flex-col justify-center items-center gap-5 px-10 py-20">
          <Icon
            icon-name="paygrade_Illustration"
            size=""
            class-name="w-32 h-32"
          />
          <p class="font-normal text-base leading-5 text-jet text-center">
            There are no timelines for this review presently.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col gap-5">
          <template v-if="true">
            <div @click="isOpen=false;$emit('click-update', review)">
              <div class="flex justify-start items-center gap-2 cursor-pointer">
                <Icon icon-name="icon-edit" class="text-blueCrayola" size="xs" />
                <span class="font-normal text-sm text-darkPurple">Edit</span>
              </div>
            </div>
          </template>
          <div v-for="(timeline, i) in timelines" :key="timeline.id">
            <div class="flex flex-col justify-start gap-2 border-b border-dashed border-romanSilver pb-5">
              <div class="leading-5 flex flex-col justify-start items-start gap-1">
                <p class="font-semibold text-base text-darkPurple">
                  {{ timeline.type }}
                </p>
                <p class="font-normal text-sm text-darkPurple">
                  {{ $DATEFORMAT(new Date(timeline.updatedAt), "dd MMMM, yyyy") }}
                </p>
              </div>
              <div class="flex justify-between items-center">
                <div class="flex justify-start items-center gap-2">
                  <p class="text-base font-normal text-darkPurple">
                    <span v-if="timeline.participants.length">
                      {{ `${timeline.participants.length} Participants` }}
                    </span>
                    <span v-else>0 Participants</span>
                  </p>
                  <div class="flex justify-start gap-1">
                    <div class="flex justify-start items-center gap-2">
                      <div v-for="participant in timeline.participants.slice(0, 3)" :key="JSON.stringify(participant)">
                        <div class="h-6 rounded-md text-blueCrayola font-normal">
                          <div v-if="participant.photo" class="h-6 w-6 rounded-md">
                            <img class="h-6 w-6 rounded-md" :src="participant.photo" />
                          </div>
                          <div v-else class="h-6 w-6 border rounded-md flex justify-center items-center">
                          <span class="rounded-md p-2 text-xs">
                            {{ $getInitials(`${participant.fname} ${participant.lname}`) }}
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="timeline.participants && timeline.participants.length - 3 > 0">
                      <p class="text-base font-semibold text-flame leading-5">
                        +{{ timeline.participants && timeline.participants.length - 3 }}
                      </p>
                    </template>
                  </div>
                </div>
                <template v-if="timeline">
                  <div @click="participants=timeline.participants;activeIndex=i;isViewParticipants=true">
                    <div class="rounded-md px-2 py-1 cursor-pointer bg-red-100">
                      <p class="text-base font-semibold text-flame leading-5">View</p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </RightSideBar>
    <RightSideBar
      v-if="isViewParticipants"
      @close="isViewParticipants = false"
      submit="Save"
      cancel="Cancel"
      button-class="bg-dynamicBackBtn text-white"
      @submit="onSubmitParticipants(activeIndex)"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="isViewParticipants = false" variant="secondary" />
          <p class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            View Participants
          </p>
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start gap-5">
          <div class="flex flex-col">
            <div v-for="(participant, i) in participants" :key="`${participant}_${i}`">
              <div class="flex justify-between items-end border-b border-dashed border-romanSilver py-3">
                <div class="flex justify-start items-start gap-2">
                  <div class="h-10 rounded-md text-blueCrayola font-normal">
                    <img class="h-10 w-10 rounded-md" v-if="participant.photo" :src="participant.photo" />
                    <div v-else class="h-10 w-10 p-2 border rounded-md flex justify-center items-center">
                    <span class="rounded-md w-10 h-10 p-2">
                      {{ $getInitials(`${participant.fname} ${participant.lname}`) }}
                    </span>
                    </div>
                  </div>
                  <div>
                    <p class="font-normal text-base text-darkPurple">
                      {{ participant.fname }} {{ participant.lname }}
                    </p>
                    <p class="font-semibold text-10px text-romanSilver uppercase">
                      <span class="flex flex-row justify-start items-center gap-0">
                        {{ participant.designation }} • {{ participant.function }}
                      </span>
                    </p>
                  </div>
                </div>
                <template v-if="true">
                  <div class="cursor-pointer" @click="participants.splice(i, 1)">
                    <div class="p-1 rounded-md" style="background:rgba(241, 90, 41, 0.16);">
                      <Icon icon-name="icon-close-flame" class="text-flame" size="xs" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <template v-if="true">
            <div @click="isOpenParticipants=true;selectedItems=JSON.parse(JSON.stringify(participants))">
              <div class="flex justify-start items-center gap-2 cursor-pointer">
                <Icon icon-name="icon-plus" class="text-flame" size="xs" />
                <p class="font-semibold text-base text-flame">Add Participant</p>
              </div>
            </div>
          </template>
        </div>
      </template>
    </RightSideBar>
    <template v-if="isOpenParticipants">
      <div class="overlayer flex flex-wrap justify-center items-center">
        <Card class="bg-white relative p-4 w-4/12">
          <div class="flex flex-col justify-start items-start gap-2">
            <h3 class="font-bold text-lg leading-5 text-darkPurple">
              Add Participant
            </h3>
            <RadioButton
              space-between="mr-2 ml-3 text-jet text-sm font-normal leading-5"
              v-model="parameters"
              default="designation"
              :options="[
                { name: 'By Designation', value: 'designation' },
                { name: 'By Job Level', value: 'level'},
              ]"
            />
            <div class="flex flex-col justify-start items-start gap-5 w-full">
              <div class="w-full" v-if="parameters === 'designation'">
                <p class="font-normal text-sm leading-5 text-jet">
                  Designation
                </p>
                <Select
                  placeholder="--Select--"
                  @input="onSearchEmployees({ designation: $event })"
                  :options="designationOptions"
                  :rules="['required']"
                />
              </div>
              <div class="w-full" v-if="parameters === 'level'">
                <p class="font-normal text-sm leading-5 text-jet">
                  Level
                </p>
                <Select
                  placeholder="--Select--"
                  @input="onSearchEmployees({ level: $event })"
                  :options="levelOptions"
                  :rules="['required']"
                />
              </div>
            </div>
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-sm text-jet">Participant</p>
              <v-select
                :clearable="false"
                :filterable="false"
                class="style-select"
                :options="employeeOptions"
                @input="onSelectedParticipant"
                @search="onSearchEmployees({search: $event, items: 100 })"
              >
                <template #option="{fname, lname, orgFunction, userDesignation, photo }">
                  <div class="flex flex-row justify-start gap-2">
                    <template>
                      <img v-if="photo" class="w-8 h-8 rounded-md" :src="photo" alt="profile pic" />
                      <div v-else class="w-8 h-8 px-2 py-2 rounded-md border flex justify-center items-center">
                        <span class="text-blueCrayola text-xs font-semibold text-center">
                          {{ $getInitials(`${fname} ${lname}`) }}
                        </span>
                      </div>
                    </template>
                    <div class="hover:text-white flex flex-col justify-start">
                      <p class="font-bold text-sm cursor-pointer">
                        {{ fname }} {{ lname }}
                      </p>
                      <div class="flex flex-row justify-start items-center gap-2" style="font-size:10px">
                        <span class="uppercase">{{ orgFunction ? orgFunction.name : '' }}</span>
                        <span class="capitalize">{{ userDesignation ? `${userDesignation.name}` : '' }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{fname, lname}">
                  <div>
                    <p class="">{{ fname }} {{ lname }}</p>
                  </div>
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon style="margin-right:14px" icon-name="caret-down" size="xsm" />
                  </span>
                </template>
                <template #list-footer>
                  <li class="text-center text-xs">
                    Type name of employee to search more
                  </li>
                </template>
              </v-select>
            </div>
            <div class="flex justify-start items-start flex-wrap gap-2 my-3">
              <div v-for="participant in selectedItems" :key="participant.id">
                <div class="bg-ghostWhite rounded-md px-2">
                  <div class="flex justify-between items-center gap-2">
                    <p class="text-jet text-base font-normal">
                      {{ participant.fname }} {{ participant.lname }}
                    </p>
                    <div @click="selectedItems.splice(participant.id, 1)" class="cursor-pointer">
                      <Icon icon-name="close-icon" class="text-jet" size="xxs" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end w-full gap-2">
              <Button
                label="Cancel" variant="secondary btn-border" style="padding:0 20px"
                @onClick="isOpenParticipants = false"
              />
              <Button
                label="Save" variant="primary btn-bg" style="padding:10px 30px"
                @onClick="participants=selectedItems;isOpenParticipants=false"
              />
            </div>
          </div>
        </Card>
      </div>
    </template>
  </ValidationObserver>
</template>

<script>
  import * as _ from 'lodash'
  import { format } from "date-fns"
  import VSelect from "vue-select"
  import "vue-select/dist/vue-select.css"
  import { ValidationObserver } from "vee-validate"
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
  import Card from "@scelloo/cloudenly-ui/src/components/card"
  import Button from "@scelloo/cloudenly-ui/src/components/button"
  import Select from "@scelloo/cloudenly-ui/src/components/select"
  import RadioButton from "@scelloo/cloudenly-ui/src/components/radio"
  import RightSideBar from "@/components/RightSideBar"
  import Icon from "@/components/Icon"

  export default {
    components: {
      ValidationObserver,
      VSelect,
      BackButton,
      Button,
      RadioButton,
      Select,
      Card,
      RightSideBar,
      Icon,
    },
    data(){
      return {
        isOpen: false,
        isLoading: true,
        review: {
          id: '',
          year: '',
          initiatedBy: {},
          effectiveDate: '',
          score_ranges: [],
        },
        activeIndex: '',
        parameters: '',
        timelines: [],
        employeeOptions: [],
        selectedItems: [],
        participants: [],
        isOpenParticipants: false,
        isViewParticipants: false,
        designationOptions: [],
        levelOptions: [],
      }
    },
    methods: {
      toggle(review){
        this.isOpen = true
        this.review = review
        this.getReviewTimelines(review.id)
      },
      onSelectedParticipant(payload){
        this.selectedItems.push(payload)
        this.selectedItems = [...new Set(this.selectedItems)]
      },
      getReviewTimelines(reviewId){
        this.$_getReviewTimelines(reviewId).then(({ data }) => {
          this.timelines = data.data
          this.isLoading = false
        })
      },
      onSubmit(){
        this.isLoading = true
        const effectiveAt = format(
          new Date(this.review.effectiveDate), "yyyy-MM-dd"
        )
        const filteredTimelines = this.timelines?.map((timeline) => ({
          ...timeline, participants: timeline.participants?.map(i => i.userId)
        }))
        const scoreRanges = this.review.score_ranges.map((range) => ({
          from: range.minScore, to: range.maxScore,
          minRange: range.minRange, maxRange: range.maxRange
        }))

        const payload = {
          ...this.review,
          reviewId: this.review.id,
          effectiveDate: effectiveAt,
          timelines: filteredTimelines,
          performanceScores: scoreRanges,
          financialYear: this.review.year,
          initiatedBy: this.review.initiatedBy.userId,
        }

        this.$_createOrgCompensationReview(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.isLoading = false
          this.isOpen = false
          this.$emit('success', data)
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      },
      onSubmitParticipants(i){
        this.timelines[i].participants = _.cloneDeep(this.participants)
        this.isViewParticipants = false
      },
      async onSearchEmployees({ search = '', items = 5, level, designation }) {
        /* eslint-disable no-param-reassign */
        if(this.parameters === 'designation'){
          search = `${search}&designationId=${designation}`
        } else if(this.parameters === 'level'){
          search = `${search}&levelId=${level}`
        }
        clearTimeout(this.timeout)
        if (!search) {
          await this.$_getAllEmployeesWithQueries(`&perPage=${items}`)
          .then(({ data }) => {
            this.employeeOptions = data.data.employees?.map(item => ({
              ...item, function: item.orgFunction?.name,
              designation: item.userDesignation?.name
            }))
          })
        }
        this.timeout = setTimeout(async () => {
          await this.$_getAllEmployeesWithQueries(`&search=${search}&perPage=${items}`)
          .then(({ data }) => {
            this.employeeOptions = data.data.employees?.map(item => ({
              ...item, function: item.orgFunction?.name,
              designation: item.userDesignation?.name
            }))
          })
        }, 500)
      },
      async getDesignation() {
        await this.$_getDesignations('').then(({ data }) => {
          this.designationOptions = data.designations.map(
            item => ({ value: item.id, ...item }))
        })
      },
      async getLevels() {
        await this.$_getLevels().then(({ data }) => {
          this.levelOptions = data.levels.map(
            item => ({ value: item.id, ...item }))
        })
      },
    },
    async created(){
      await this.getDesignation()
      await this.getLevels()
      await this.onSearchEmployees({})
    }
  }
</script>

<style lang="scss">
  .rightSidebar{
    width: 520px !important;
  }
  .overlayer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 5;
  }
  .daterange-picker {
    position: absolute !important;
    z-index: 10000;
    .calendar{
      display: none;
    }
    .inputs-container {
      border: 0 !important;
    }
    .divider {
      padding: 0 !important;
    }
    input {
      font-size: 13px !important;
      width: 90px;
    }
  }
  .mx-datepicker, .mx-datepicker-range {
    width: 100% !important;
  }
  .mx-input {
    box-shadow: none !important;
    border: none !important;
    width: 95% !important;
  }
  .mx-icon-calendar {
    display: none !important;
  }
  .timeline-class {
    display: none!important;
  }
  .text-10px {
    font-size: 10px !important;
  }

  .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>

